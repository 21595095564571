<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        :href="platformUrl()"
      >
        <platform-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ platformName() }}
        </h2>
        <small
          v-if="platformVersionName()"
          class="pl-50"
        >
          {{ platformVersionName() }}
        </small>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          v-if="isDemo"
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-text class="mb-2" />

          <div class="text-center">
            <b-spinner
              variant="primary"
              label="Please wait ..."
            />
          </div>

        </b-col>
        <b-col
          v-else
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('auth.login.title', {platform: platformName()}) }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('auth.login.subtitle') }}
          </b-card-text>

          <!-- alert -->
          <template
            v-for="(error_v, error_k) in errors"
          >
            <b-alert
              :key="error_k"
              variant="danger"
              dismissible
              show
              class="mb-1"
            >
              <div class="alert-body">
                <p>
                  <small class="mr-50"><span
                    v-if="error_k && Number.isNaN(error_k)"
                    class="font-weight-bold"
                  >{{ error_k }}:</span> {{ Array.isArray(error_v) ? Array.of(error_v).join(", ") : error_v }}</small>
                </p>
              </div>
            </b-alert>
          </template>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                :label="$t(`inputs.email`)"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`inputs.email`)"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="myemail@example.com"
                    :disabled="isLoading"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t(`inputs.password`) }}</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>{{ $t('auth.login.forgot_password') }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(`inputs.password`)"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                      :disabled="isLoading"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                  :disabled="isLoading"
                >
                  {{ $t('auth.login.remember_me') }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                id="submitBtn"
                type="submit"
                variant="primary"
                block
                :disabled="invalid || isLoading"
              >
                <template v-if="isLoading">
                  <b-spinner small />
                  <span class="sr-only">Loading...</span>
                </template>
                <span v-else>{{ $t('auth.login.sign_in') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('auth.login.dont_have_account') }} </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;{{ $t('auth.login.create_an_account') }}</span>
            </b-link>
          </b-card-text>

          <!--          &lt;!&ndash; divider &ndash;&gt;-->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              or-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          &lt;!&ndash; social buttons &ndash;&gt;-->
          <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
          <!--            <b-button-->
          <!--              variant="facebook"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="FacebookIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="twitter"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="TwitterIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="google"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="MailIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="github"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="GithubIcon" />-->
          <!--            </b-button>-->
          <!--          </div>-->

          <!--          &lt;!&ndash; divider &ndash;&gt;-->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              -->
          <!--            </div>-->
          <!--          </div>-->

          <language-switcher />
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
    <div id="hiddenBtn" />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip, BSpinner,
} from 'bootstrap-vue'
import useSession from '@/auth/jwt/useJwt'
// import useSession from '/src/@core/auth/session/useSession'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser, setUserData } from '@/auth/utils'
import FakeRecaptcha from '@core/utils/fake-recaptcha'
import { getAuth, RecaptchaVerifier } from 'firebase/auth'

import {
  catchException, isDemoHost, isUnitTestMode, platformName, platformUrl, platformVersionName, toast,
} from '@core/utils/utils'
import LanguageSwitcher from '@/views/pages/authentication/LanguageSwitcher.vue'
import { initializeApp } from 'firebase/app'
import firebaseConfig from '@core/configs/firebase.json'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    LanguageSwitcher,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    PlatformLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '', // '12345678',
      userEmail: '', // 'admin@fashion.com',
      isLoading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      errors: {
      },
      recaptcha: null,
      isDemo: isDemoHost(),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  beforeMount() {
    if (this.isDemo) {
      this.demoLogin()
    }
  },
  mounted() {
    this.initRecaptcha()
  },
  methods: {
    platformUrl,
    platformVersionName,
    platformName,
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.errors = {}
          this.recaptcha.verify().then(recaptchaToken => {
            useSession.csrf().then(() => {
              useSession.login({
                email: this.userEmail,
                password: this.password,
                token: recaptchaToken,
              })
                .then(response => {
                  this.postLoginProcedures(response)
                })
                .catch(error => {
                  if (error?.response?.status === 422) {
                    const response = error.response ? error.response.data : error
                    const responseErrors = response.errors
                    this.errors = responseErrors
                    this.$refs.loginForm.setErrors(responseErrors)
                  } else {
                    catchException(error)
                    toast('danger', error?.response?.data?.message || error?.message || 'Something went wrong!'/* , errorText */)
                  }
                })
                .finally(() => {
                  this.isLoading = false
                })
            }).catch(() => {
              this.isLoading = false
            })
          }).catch(error => {
            this.isLoading = false
            catchException(error)
          }).finally(() => {
            this.recaptcha.recaptcha.reset()
          })
        }
      })
    },
    demoLogin() {
      this.isLoading = true
      useSession.csrf().then(() => {
        useSession.demoLogin({
          // token: recaptchaToken,
        })
          .then(response => {
            this.postLoginProcedures(response)
          })
          .catch(error => {
            catchException(error)
            // toast('danger', error?.response?.data?.message || error?.message || 'Something went wrong!'/* , errorText */)
            window.location.href = platformUrl()
          })
      })
    },
    initRecaptcha() {
      // Initialize Firebase
      const app = initializeApp(firebaseConfig)
      const auth = getAuth(app)

      if (isUnitTestMode()) {
        console.log('Unit Test Mode')
        this.recaptcha = new FakeRecaptcha()
        return
      }
      this.recaptcha = new RecaptchaVerifier(auth, 'hiddenBtn', {
        size: 'invisible',
        callback: () => {},
      })

      this.recaptcha.render()
    },
    postLoginProcedures(response) {
      const responseData = response.data
      const itemData = responseData.user
      const storeData = responseData.store
      const userRole = 'admin' // itemData.role
      useSession.setToken(responseData.access_token)
      // useSession.setRefreshToken(response.data.refreshToken)
      setUserData(itemData)
      this.$store.commit('storeConfig/UPDATE_STORE_DATA', storeData)

      this.$ability.update(itemData.ability)

      // ? This is just for demo purpose as well.
      // ? Because we are showing eCommerce app's cart items count in navbar
      // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', itemData.eCommerceCartItemsCount)

      if (storeData.layout_config) {
        this.$store.commit('appConfig/UPDATE_SKIN', storeData.layout_config.skin)
        this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', Boolean(storeData.layout_config.menu_collapsed))
      }

      let returnToRoute = this.$router.currentRoute.query.return
      if (returnToRoute === '/login') {
        returnToRoute = null
      }

      // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      this.$router.replace(returnToRoute || getHomeRouteForLoggedInUser(userRole))
        .then(() => {
          if (!this.isDemo) {
            toast(
              'success',
              this.$t('auth.login.success_login.title', { name: itemData.fullName || itemData.username }),
              this.$t('auth.login.success_login.text'),
            )
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
